import * as React from "react"
import Users from "../images/users.svg"
import '../css/styles.scss'
import { useAppState } from "../components/context"
import { Link } from "gatsby";
import Layout from "../components/Layout";
import Arrow from '../images/icons/arrow.svg'
import CreatePassword from "../components/createPassword";
import { Router } from '@reach/router'
import CookieBar from "../components/CookieBar";
// import { Helmet } from "react-helmet"
// import Favicon from '../images/favicon.png'
export default function App() {

  const { isLoggedIn, customerData } = useAppState();
  // console.log('isLoggedIn: ' + isLoggedIn);
  return (
    <Layout>
      {/* <Helmet>
        <title>Pausit | Webbshop</title>
        <link rel="shortcut icon" href={Favicon}></link>
      </Helmet> */}
      <div className="App">
        <span className="link">
          Starta gratis provperiod
        </span>
        <h2>Hur många ska använda Pausit?</h2>
        <p>Om ni kan bli fler än 20 som ska använda Pausit efter provperioden ska ni här välja fler än 20.</p>
        <div className="options">
          <Link to={!isLoggedIn ? "/register" : "/shop"}>
            <div className="option">
              <div className="circle">
                <img src={Users} alt="users" />
                <div><small>1-20</small></div>
              </div>
              <span>
                Upp till 20
                <img src={Arrow} alt="arrow" />
              </span>
            </div>
          </Link>
          <Link to="/contact">
            <div className="option">
              <div className="circle">
                <img src={Users} alt="users" />
                <div><small>21+</small></div>
              </div>
              <span>
                Fler än 20
                <img src={Arrow} alt="arrow" />
              </span>
            </div>
          </Link>
        </div>
      </div>
      <Router>
        <CreatePassword path="createpassword/:key/:login" />
      </Router>
      {/* <CookieBar /> */}
    </Layout>
  )
}
